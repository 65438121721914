/* App.css */
.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.App-header {
  width: 90%;
  max-width: 600px;
}

textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none; /* ユーザーがサイズを変更できないようにする */
}

/* 画面の幅が600px以上の場合 */
@media (min-width: 600px) {
  textarea {
    height: 200px;
  }
}

/* 文字数表示のスタイル */
div {
  margin-top: 10px;
}

